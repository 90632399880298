import compact from 'lodash/compact';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import {
  InvoiceStatuses,
  InvoiceTypes
} from '../../../../../../../../invoices/invoicesTypes';
import { ItemMessagesListInvoiceMessageHeaderButtonsMessage } from './ItemMessagesListInvoiceMessageHeaderButtons.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { ChargebackInvoiceModalButton } from '../../../../../../../../invoices/components/modalButtons/ChargebackInvoiceModalButton';
import { ConfirmPaymentInvoiceModalButton } from '../../../../../../../../invoices/components/modalButtons/ConfirmPaymentInvoiceModalButton';
import { DownloadInvoiceButton } from '../../../../../../../../invoices/components/buttons/DownloadInvoiceButton';
import { SendToEmailInvoiceModalButton } from '../../../../../../../../invoices/components/modalButtons/SendToEmailInvoiceModalButton';
import { VoidInvoiceModalButton } from '../../../../../../../../invoices/components/modalButtons/VoidInvoiceModalButton';
import { PayInternalInvoiceModalButton } from '../../../../../../../../invoices/components/modalButtons/PayInternalInvoiceModalButton';
import { MessageVisibleForClientButton } from '../../../../../../buttons/MessageVisibleForClientButton';
import { CancelInvoiceSmartContractsModalButton } from '../../../../../../../../invoices/components/modalButtons/CancelInvoiceSmartContractsModalButton';
import { InvoiceLinkButton } from '../../../../../../../../invoices/components/buttons/InvoiceLinkButton';

import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { DropdownHelper } from '../../../../../../../../../helpers/dropdowns/DropdownHelper';
import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';
import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';
import { PureLinkHelper } from '../../../../../../../../../helpers/links/PureLinkHelper';

import { InvoicePath } from '../../../../../../../../invoices/InvoicePath';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

import { invoicesKeys, words } from '../../../../../../../../../locales/keys';

interface ItemMessagesListInvoiceMessageHeaderButtonsProps {
  isOpen: boolean;
  message: ItemMessagesListInvoiceMessageHeaderButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  toggleIsOpen: () => void;
}

function ItemMessagesListInvoiceMessageHeaderButtons({
  isOpen,
  message,
  messagesCacheKey,
  toggleIsOpen
}: ItemMessagesListInvoiceMessageHeaderButtonsProps) {
  const bitInvoice = message.bitInvoice;
  const showCancelSmartContractsButton =
    bitInvoice?.invoiceType === InvoiceTypes.DEPOSIT &&
    (bitInvoice?.status === InvoiceStatuses.SENT ||
      bitInvoice?.status === InvoiceStatuses.PAID);

  const dropdownButtonsPermissions = compact([
    MessagesPermissions.READ_MESSAGE_INVOICE_VIEW_INVOICE_BUTTON,
    bitInvoice?.status === InvoiceStatuses.PAID
      ? MessagesPermissions.READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON
      : null,
    bitInvoice?.showVoidButton
      ? MessagesPermissions.READ_MESSAGE_INVOICE_VOID_BUTTON
      : null,
    bitInvoice?.showPayWireButton ||
    bitInvoice?.secondaryInvoice?.showPayWireButton
      ? MessagesPermissions.READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON
      : null,
    showCancelSmartContractsButton
      ? MessagesPermissions.READ_MESSAGE_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON
      : null
  ]);

  return (
    <div className="absolute -top-px -right-px flex -space-x-px">
      <div className="relative flex items-center justify-center">
        <CheckPermissions actions={dropdownButtonsPermissions}>
          <DropdownHelper
            buttonClassName="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
            dropdownPlacement={PopoverPlacement.BOTTOM_END}
            icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          >
            <CheckPermissions
              action={
                MessagesPermissions.READ_MESSAGE_INVOICE_VIEW_INVOICE_BUTTON
              }
            >
              <PureLinkHelper
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                href={InvoicePath.paymentsInvoice(message.bitInvoice?.nanoId)}
                i18nText={invoicesKeys.view}
              />
            </CheckPermissions>

            {/* Chargeback */}
            {bitInvoice?.showRefundButton && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_CHARGEBACK_BUTTON
                }
              >
                <ChargebackInvoiceModalButton
                  invoiceId={message.invoiceId}
                  cacheKeys={[messagesCacheKey]}
                  i18nText={invoicesKeys.chargeback}
                />
              </CheckPermissions>
            )}

            {/* Cancel smart contracts */}
            {showCancelSmartContractsButton && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_CANCEL_SMART_CONTRACTS_BUTTON
                }
              >
                <CancelInvoiceSmartContractsModalButton
                  cacheKeys={[messagesCacheKey]}
                  i18nText={invoicesKeys.cancelSmartContracts}
                  invoiceId={message.invoiceId}
                />
              </CheckPermissions>
            )}

            {/* Void */}
            {bitInvoice?.showVoidButton && (
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_VOID_BUTTON}
              >
                <VoidInvoiceModalButton
                  disabled={bitInvoice.disabledVoidButton}
                  cacheKeys={[messagesCacheKey]}
                  i18nText={words.void}
                  invoiceId={message.invoiceId}
                />
              </CheckPermissions>
            )}
            {/* Pay from AV */}
            {(bitInvoice?.showPayDepositButton ||
              bitInvoice?.secondaryInvoice?.showPayDepositButton) && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_PAY_INTERNAL_BUTTON
                }
              >
                <PayInternalInvoiceModalButton
                  cacheKeys={[messagesCacheKey]}
                  disabled={
                    bitInvoice?.showPayDepositButton
                      ? bitInvoice?.disabledPayDepositButton
                      : bitInvoice?.secondaryInvoice?.disabledPayDepositButton
                  }
                  i18nText={invoicesKeys.payFromAv}
                  invoiceId={
                    bitInvoice?.showPayDepositButton
                      ? bitInvoice?.id
                      : bitInvoice?.secondaryInvoice?.id
                  }
                />
              </CheckPermissions>
            )}

            {/* Confirm payment */}
            {(bitInvoice?.showPayWireButton ||
              bitInvoice?.secondaryInvoice?.showPayWireButton) && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_CONFIRM_PAYMENT_BUTTON
                }
              >
                <ConfirmPaymentInvoiceModalButton
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={invoicesKeys.confirmPayment}
                  invoiceId={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.id
                      : bitInvoice.secondaryInvoice?.id
                  }
                  invoiceAmount={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.amount
                      : bitInvoice.secondaryInvoice?.amount
                  }
                  disabled={
                    bitInvoice.showPayWireButton
                      ? bitInvoice.disabledPayWireButton
                      : bitInvoice.secondaryInvoice?.disabledPayWireButton
                  }
                />
              </CheckPermissions>
            )}

            {/* Copy link */}
            {message.invoiceId && (
              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_COPY_LINK_BUTTON
                }
              >
                <InvoiceLinkButton
                  className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                  i18nText={words.copyLink}
                  invoiceId={message.bitInvoice?.nanoId}
                />
              </CheckPermissions>
            )}
          </DropdownHelper>
        </CheckPermissions>
      </div>

      {/* visible for clients */}
      <CheckPermissions
        action={
          MessagesPermissions.READ_MESSAGE_INVOICE_VISIBLE_FOR_CLIENTS_BUTTON
        }
      >
        <MessageVisibleForClientButton
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={
            message.visibleForClient
              ? IconsEnum.EYE_SOLID
              : IconsEnum.EYE_OFF_SOLID
          }
          uuid={message.uuid}
          cacheKey={messagesCacheKey}
          tooltipI18nText={
            message.visibleForClient ? words.makeInvisible : words.makeVisible
          }
        />
      </CheckPermissions>

      {/* Mail */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_MAIL_BUTTON}
      >
        <SendToEmailInvoiceModalButton
          invoiceId={message.invoiceId}
          invoiceBillingInfo={bitInvoice?.invoiceBillingInfo}
          team={bitInvoice?.generalLedger?.company}
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={IconsEnum.MAIL}
          tooltipI18nText={words.sendToEmail}
        />
      </CheckPermissions>

      {/* Download */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_DOWNLOAD_BUTTON}
      >
        <DownloadInvoiceButton
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={IconsEnum.DOWNLOAD_SOLID}
          invoiceId={message.invoiceId}
          tooltipI18nText={words.download}
        />
      </CheckPermissions>

      {/* Toggle */}
      <CheckPermissions
        action={MessagesPermissions.READ_MESSAGE_INVOICE_TOGGLE_BUTTON}
      >
        <PureIconButtonHelper
          className="p-1.5 flex justify-center items-center border dark:border-gray-800 hover:bg-gray-200 dark:hover:bg-gray-800"
          icon={isOpen ? IconsEnum.CHEVRON_UP : IconsEnum.CHEVRON_DOWN_SOLID}
          onClick={toggleIsOpen}
        />
      </CheckPermissions>
    </div>
  );
}

export default ItemMessagesListInvoiceMessageHeaderButtons;
