import React, { ReactNode, memo, useRef, useCallback, useState } from 'react';

import { StickyBottomScrollContainerScrollbar } from './components/StickyBottomScrollContainerScrollbar';

interface StickyBottomScrollContainerProps {
  children: ReactNode;
  containerClass?: string;
  settings?: ReactNode;
}

function StickyBottomScrollContainer({
  children,
  containerClass,
  settings
}: StickyBottomScrollContainerProps) {
  const contentWrapperRef = useRef<HTMLDivElement | null>(null);

  const [containerElem, setContainerElem] = useState<HTMLElement>();

  const containerCallbackRef = useCallback(
    (elem: HTMLElement | null) => {
      if (elem) {
        setContainerElem(elem);
      }
    },
    [setContainerElem]
  );

  return (
    <>
      <div
        ref={containerCallbackRef}
        className={containerClass || 'overflow-x-auto'}
      >
        <div ref={contentWrapperRef} className="w-fit min-w-full">
          {children}
        </div>
      </div>
      {settings}
      <StickyBottomScrollContainerScrollbar
        containerElem={containerElem}
        contentWrapperRef={contentWrapperRef}
      />
    </>
  );
}

export default memo(StickyBottomScrollContainer);
