import React, {
  ReactNode,
  useCallback,
  MouseEventHandler,
  useRef
} from 'react';
import cl from 'classnames';

import {
  ClassName,
  ID,
  IsSelected,
  TableViewType,
  UUID
} from '../../../../../types';

import { OnSetCheckedIds } from '../../../../../common/hooks/useTableCheckable';

import { IndexTableRowCheckbox } from '../IndexTableRowCheckbox';
import { IndexTableRowEditButton } from '../IndexTableRowEditButton';
import { IndexTableRowExpandButton } from '../IndexTableRowExpandButton';
import { IndexTableCell } from '../IndexTableCell';
import { IndexTableCellUuid } from '../IndexTableCellUuid';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { AdminPermissions } from '../../../../admin/adminConstants';

const selectedInContextMenuStyle = {
  backgroundColor: 'rgba(59, 130, 246, 0.1)'
};

interface IndexTableRowDefaultProps {
  children: ReactNode;
  className?: ClassName;
  crossed?: boolean;
  expandable?: boolean;
  itemUuid?: UUID;
  onContextMenu?: MouseEventHandler<HTMLTableRowElement>;
  selected?: IsSelected;
  selectedInContextMenu?: IsSelected;
  viewType?: TableViewType;
  withoutCheckbox?: boolean;
}

interface IndexTableRowWithCheckedProps {
  scope: string;
  checked?: boolean;
  checkboxDisabled?: boolean;
  itemId?: ID;
  onCheck?: OnSetCheckedIds;
}

interface IndexTableRowWithoutCheckedProps {
  scope?: never;
  checked?: never;
  checkboxDisabled?: never;
  itemId?: never;
  onCheck?: never;
}

interface IndexTableRowWithShowProps {
  onShow: (itemUuid: UUID) => void;
  onShowMouseOver?: (itemUuid: UUID) => void;
}

interface IndexTableRowWithoutShowProps {
  onShow?: never;
  onShowMouseOver?: never;
}

interface IndexTableRowWithRowShowProps {
  onRowShow: (itemUuid: UUID) => void;
  onRowShowMouseOver?: (itemUuid: UUID) => void;
}

interface IndexTableRowWithoutRowShowProps {
  onRowShow?: never;
  onRowShowMouseOver?: never;
}

interface IndexTableRowWithEditProps {
  onEdit: (itemUuid: UUID) => void;
  onEditMouseOver?: (itemUuid: UUID) => void;
}

interface IndexTableRowWithoutEditProps {
  onEdit?: never;
  onEditMouseOver?: never;
}

type IndexTableRowProps = IndexTableRowDefaultProps &
  (IndexTableRowWithCheckedProps | IndexTableRowWithoutCheckedProps) &
  (IndexTableRowWithShowProps | IndexTableRowWithoutShowProps) &
  (IndexTableRowWithRowShowProps | IndexTableRowWithoutRowShowProps) &
  (IndexTableRowWithEditProps | IndexTableRowWithoutEditProps);

function IndexTableRow({
  checkboxDisabled,
  checked,
  children,
  className,
  crossed,
  expandable,
  itemId,
  itemUuid,
  onCheck,
  onContextMenu,
  onEdit,
  onEditMouseOver,
  onRowShow,
  onRowShowMouseOver,
  onShow,
  onShowMouseOver,
  scope,
  selected,
  selectedInContextMenu,
  viewType,
  withoutCheckbox = false
}: IndexTableRowProps) {
  const rowElementRef = useRef<HTMLTableRowElement>(null);

  const handleShowButtonClick = useCallback(
    () => onShow?.(itemUuid),
    [itemUuid, onShow]
  );

  const handleShowButtonMouseOver = useCallback(
    () => onShowMouseOver?.(itemUuid),
    [itemUuid, onShowMouseOver]
  );

  const handleShowRowButtonClick = useCallback<
    MouseEventHandler<HTMLTableRowElement>
  >(
    (e) => {
      const closestClickableElement = (e?.target as HTMLElement)?.closest(
        'button, a, tr'
      );

      if (closestClickableElement === rowElementRef.current) {
        onRowShow?.(itemUuid);
      }
    },
    [itemUuid, onRowShow]
  );

  const handleShowRowButtonMouseOver = useCallback(
    () => onRowShowMouseOver?.(itemUuid),
    [itemUuid, onRowShowMouseOver]
  );

  const handleEditButtonClick = useCallback(
    () => onEdit?.(itemUuid),
    [itemUuid, onEdit]
  );

  const handleEditButtonMouseOver = useCallback(
    () => onEditMouseOver?.(itemUuid),
    [itemUuid, onEditMouseOver]
  );

  const handleContextMenu = useCallback<MouseEventHandler<HTMLTableRowElement>>(
    (event) => {
      if (onContextMenu) {
        !checked && !withoutCheckbox && onCheck?.(itemId);
        onContextMenu(event);
      }
    },
    [checked, itemId, onCheck, onContextMenu, withoutCheckbox]
  );

  return (
    <tr
      ref={rowElementRef}
      className={
        className ||
        cl('hover:bg-gray-500 hover:bg-opacity-5 group', {
          'bg-gray-500 bg-opacity-10 dark:bg-opacity-20': selected,
          'bg-crossed': crossed
        })
      }
      onClick={onRowShow ? handleShowRowButtonClick : undefined}
      onContextMenu={handleContextMenu}
      onMouseEnter={onShowMouseOver ? handleShowRowButtonMouseOver : undefined}
      style={selectedInContextMenu ? selectedInContextMenuStyle : undefined}
    >
      {onCheck && !withoutCheckbox ? (
        <IndexTableRowCheckbox
          scope={scope}
          checked={checked}
          disabled={checkboxDisabled}
          itemId={itemId}
          viewType={viewType}
          onCheck={onCheck}
        />
      ) : null}

      {children}

      <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_ID}>
        {itemId ? (
          <IndexTableCell text={itemId as string} viewType={viewType} />
        ) : null}
      </CheckPermissions>
      <CheckPermissions action={AdminPermissions.ADMIN_READ_ANY_UUID}>
        {itemUuid ? (
          <IndexTableCellUuid uuid={itemUuid} viewType={viewType} />
        ) : null}
      </CheckPermissions>

      {onShow ? (
        <IndexTableRowExpandButton
          onClick={handleShowButtonClick}
          onMouseEnter={handleShowButtonMouseOver}
          viewType={viewType}
        />
      ) : null}
      {onEdit && expandable ? (
        <IndexTableRowExpandButton
          onClick={handleEditButtonClick}
          onMouseEnter={handleEditButtonMouseOver}
          viewType={viewType}
        />
      ) : null}
      {onEdit && !expandable ? (
        <IndexTableRowEditButton
          onClick={handleEditButtonClick}
          onMouseEnter={handleEditButtonMouseOver}
        />
      ) : null}
    </tr>
  );
}

export default IndexTableRow;
